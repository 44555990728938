html, body {
  padding: 0;
  margin: 0;
  border: 0;
  outline: 0;
}

h0, h1, h2, h3, h4, h5, h6 {
  font-family: 'Merriweather', serif;
}

span, a {
  font-family: 'Merriweather', serif;
}

p, ul {
  font-family: "Montserrat", sans-serif;
}

hr {
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, .1);
}

/* ------------------------------------------------------------------ */
/* a. Header Section
/* ------------------------------------------------------------------ */

/*Heading*/
.hero-section {
  position: relative;
  height: 100vh;
  background-size: cover;
}

.background-image {
  background: url(../public/images/nyc.jpg) fixed no-repeat center center;
  filter: contrast(80%);
  height: 100vh;
  background-size: cover;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.hero-section .overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0.2;
  z-index: 2;
  background-color: #0f3057;
  background-image: -webkit-gradient(linear, 0 0, 100% 100%, color-stop(.25, #F9F9F9), color-stop(.25, transparent), color-stop(.5, transparent), color-stop(.5, #F9F9F9), color-stop(.75, #F9F9F9), color-stop(.75, transparent), to(transparent));
  -webkit-background-size: 7px 7px;
}

.hero-section .container-fluid {
  position: relative;
  top: 40%;
  text-align: center;
  color: black;
  z-index: 3;
}

.hero-section .container-fluid h1 {
  font-size: 4rem;
}

.hero-section .container-fluid p {
  font-size: 2rem;
}

.resume-button {
  /* margin-top: 6px; */
  border-style: solid;
  border: 1px solid black;
  /* background: #444; */
  background-color: rgba(255, 255, 255, 0.7)
}


.resume-button, .resume-button:visited {
  /* background: #179038; */
  color: #000;
  display: inline-block;
  /* text-decoration: none; */
  letter-spacing: 0;
  /* color: #fff; */
  padding: 0px 20px;
  margin-bottom: 18px;
  /* border: none; */
  cursor: pointer;
  height: auto;
  /* -webkit-transition: all .2s ease-in-out;
  -moz-transition: all .2s ease-in-out;
  -o-transition: all .2s ease-in-out;
  -ms-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out; */
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  -khtml-border-radius: 3px;
  border-radius: 12px;
}

.resume-button:hover {
  color: #fff;
  border-color: #fff;
  background-color: rgba(0, 0, 0, 0.7)
}

/* Heading Animation */

.line-1 {
  /* position: relative; */
  top: 3rem;
  margin: 0 auto;
  border-right: 2px solid rgba(255, 255, 255, .75);
  font-size: 180%;
  text-align: center;
  /* white-space: nowrap; */
  overflow: hidden;
  transform: translateY(-50%);
}

@media only screen and (min-width: 576px) {
  .line-1 {
    position: relative;
    top: 3rem;
    margin: 0 auto;
    border-right: 2px solid rgba(255, 255, 255, .75);
    font-size: 180%;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    transform: translateY(-50%);
  }

  .anim-typewriter {
    animation: typewriter 3s steps(16) 1s 1 normal both,
      blinkTextCursor 500ms steps(16) infinite normal;
  }

  @keyframes typewriter {
    from {
      width: 0;
    }

    to {
      width: 500px;
    }
  }

  @keyframes blinkTextCursor {
    from {
      border-right-color: rgba(255, 255, 255, .75);
    }

    to {
      border-right-color: transparent;
    }
  }
}

/* ------------------------------------------------------------------ */
/* b. About Section
/* ------------------------------------------------------------------ */

#about {
  margin-top: 40px;
}

.about-container {
  padding: 8em 0 3em;
  max-width: 1200px;
  width: 90%;
  margin: 0 auto;
  display: block;
}

.profile-description {
  text-align: center;
  position: relative;
}

.avatar-section {
  width: 100%;
  position: relative;
  z-index: 100;
  backface-visibility: hidden;
}

.avatar {
  overflow: hidden;
  max-width: 530px;
  box-shadow: 0 52px 74px rgb(0 21 64 / 14%);
  margin: 0 auto 4em;
}

#about img {
  display: block;
  width: 100%;
  height: auto;
  border-radius: 12px;
}

@media (min-width: 1200px) {
  .about-container {
    padding: 14em 0 5em;
    width: 100%;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
}

/* ------------------------------------------------------------------ */
/* c. Experience Section
/* ------------------------------------------------------------------ */

#experience {
  background: #f1f1e9;
  color: #424242;
  display: block;
}

.experience-title-section {
  text-align: center;
  padding-top: 3%;
  padding-bottom: 2%;
  font-size: 130%;
}

.companies-section {
  max-width: 1000px;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}

.column {
  position: relative;
  width: 100%
}

.single-company-container {
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-left: 20px;
  margin-left: 10px;
}

.single-company-left-column {
  flex: 0 0 100%;
  max-width: 100%;
}

.single-company-right-column {}

@media (min-width: 992px) {
  .single-company-left-column {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .single-company-right-column {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
}

@media (min-width: 768px) {
  .single-company-right-column {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
}


/* ------------------------------------------------------------------ */
/* d. Portfolio Section
/* ------------------------------------------------------------------ */

.works-title-section {
  text-align: center;
  padding-top: 3%;
  padding-bottom: 2%;
}

.works-section {
  padding: 3em 0 6em;
  margin-left: auto;
  margin-right: auto;
  /* padding-left: 16px;
  padding-right: 16px; */
  /* padding: 6% 10%; */
}

.rowing {
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  /* margin-right: -15px;
  margin-left: -15px; */
}

.square {
  width: 299px;
  /* height: 299px; */
  width: 100%;
}

.square img {
  width: 150;
  height: 150px;
}

.square-body {
  height: 180px;
  flex: 1 1 auto;
  padding: 1.25rem;
}

.square-body-card {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, .125);
  border-radius: 0.25rem;
}

.square-body-card a {
  color: inherit;
  text-decoration: inherit;
}

@media (min-width: 768px) {
  .square {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media (min-width: 992px) {
  .square {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
}

/* ------------------------------------------------------------------ */
/* d. Footer and Social Sections
/* ------------------------------------------------------------------ */

.heading-section .social a {
  color: #000
}

.heading-section .social a:hover {
  color: #fff;
}

.social p {
  color: #fff;
}

.footer-container {
  background: #000;
  padding-top: 48px;
  padding-bottom: 48px;
  /* color: #303030; */
  font-size: 14px;
  text-align: center;
  position: relative;
  overflow: hidden;
}

.social {
  margin: 24px 0;
  padding: 0;
  font-size: 30px;
  text-shadow: 0px 1px 2px rgba(0, 0, 0, .8);
}

.social li {
  display: inline-block;
  margin: 0 15px;
  padding: 0;
}

.social a {
  color: rgb(255, 255, 255);
  padding: 12px
}

.social a:hover {
  color: #11ABB0;
}

scrolldown link .scrolldown a {
  position: absolute;
  bottom: 30px;
  left: 50%;
  margin-left: -29px;
  color: #fff;
  display: block;
  height: 42px;
  width: 42px;
  font-size: 42px;
  line-height: 42px;
  color: #fff;
  border-radius: 100%;

  -webkit-transition: all .3s ease-in-out;
  -moz-transition: all .3s ease-in-out;
  -o-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
}

.scrolldown a:hover {
  color: #11ABB0;
}

/* ------------------------------------------------------------------ */
/* e. Navbar
/* ------------------------------------------------------------------ */

header {
  position: fixed;
  top: 0;
  height: 28px;
  width: 100%;
  /* display: flex;
  align-items: flex-end; */
  justify-content: center;
  z-index: 10000;
}

@media (min-width: 1000px) {
  header, .nav-big {
    background-color: rgba(0, 0, 0, 0.70);
    box-shadow: 1px 1px 1px 1px rgba(116, 110, 110, 0.199);
  }
}

@media (min-width: 0) {
  .nav-big {
    background-color: rgba(0, 0, 0, 0.0);
    box-shadow: none;
  }
}

.header-inner {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: auto;
  margin-left: auto;
}

.hamburger-btn {
  z-index: 10000;
  outline: none;
  border: none;
  font-size: 1.3rem;
  position: absolute;
  width: 40px;
  height: 40px;
  padding: 0px;
  margin: 0px;
  right: 30px;
  background-color: rgba(0, 0, 0, 0.7);
  color: rgba(255, 255, 255, 0.8);
  text-align: center !important;
  display: inline-block;
  -webkit-text-size-adjust: 100%;
  -webkit-appearance: none;
}

.hamburger-btn span {
  text-align: center !important;
}

.hamburger-btn:focus {
  border: none;
  outline: none;
}

.addTransiton {
  transform: translateX(170px);
  transition: transform 0.5s ease-in-out;
}

.removeTransition {
  transform: translateX(20px);
  transition: transform 0.5s ease-in-out;
}

#sidebar-list {
  background-color: rgb(0, 0, 0, 0.7);
  height: 100vh;
  width: 170px;
  position: absolute;
  z-index: 2000;
  right: 0;
  top: 0;
  /* margin-top: 30px; */
}

.nav-big {
  list-style: none;
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-weight: bold;
  margin-top: 5px;
}

.nav-big-link {
  cursor: pointer;
  color: white;
  text-decoration: none !important;
  flex: 0 0 25%;
  text-align: center;
}

.nav-big-link:hover {
  color: #11ABB0
}

.nav-small {
  display: flex;
  flex-direction: column;
  text-align: right;
  justify-content: space-around;
  margin: auto;
  height: 20%;
  margin-top: 50px;
  width: 80%;
}

.nav-small-link {
  cursor: pointer;
  color: whitesmoke;
  padding-bottom: 5px;
  padding-right: 40px;
}

.nav-small-link:hover {
  color: #11ABB0
}